:root {
  --text-color: #626262;
  --text-color-light: #b5afa7;
  --text-color-dark: #c3c3c3;
  --text-color-darker: #414649;
  --selected-kana-bg: #676767;
  --background-color: white;

  /* --text-color: #dfdfdf;
  --text-color-light: #b5b5b5;
  --text-color-dark: #d5d5d5;
  --text-color-darker: #ffffff;
  --selected-kana-bg: #4c4c4c;
  --background-color: #282828; */

  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  height: 100%;
  width: 100% !important;
}

#root {
  height: 100%;
  width: 100% !important;
  background-color: var(--background-color);
}

body {
  height: 100% !important;
}

*{
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

/* app container */
.app-container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(3, 1fr);
  grid-gap: 10px;
  width: 100%;
  height: 100%;
}

/* banner */
.banner-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 5em;
}

.banner-title {
  font-size: 1.6em;
  letter-spacing: .2em;
  padding: 0.4em;
  color: var(--text-color);

}

.banner-subtitle {
  font-size: 1.1em;
  color: var(--text-color-light);
  white-space: pre;

}

/* active-word */
#starting-text {
  font-size: 1.75em;
  color: var(--text-color-light);
}

#active-word {
    color: var(--text-color);
    font-size: 4vh;
}

#active-word-romaji {
    color: var(--text-color);
    font-size: 3.5vh;
}

#active-word-definition {
    color: var(--text-color);
    font-size: 2.75vh;
    letter-spacing: .05em;
}

.active-word-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 25px;
    row-gap: 25px;
    overflow: auto;
}

/* app menu */
.menu-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;
  row-gap: 50px;
}

.menu-item {
  color: var(--text-color);
  font-size: 1.3em;
  cursor: pointer;
  margin-top: 20px;
  letter-spacing: .05em;
}

.menu-item:hover {
  color: var(--text-color-light);
}

/* kana menu */
@keyframes slide-up {
  from {opacity: 0}
  to {opacity: 95}
}

.kana-menu-container {
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  overflow: hidden;
  position: fixed;
  background-color: var(--background-color);
  animation-name: slide-up;
  animation-duration: 1s;
}

.kana-menu-controls-container {
  display: flex;
  flex-direction: row;
  padding-top: 2.5vh;
  width: 22.727272vh;
  margin: auto;
  padding-left: 2vh;
}

.kana-menu-exit-button {
  font-size: 2vh;
  cursor: pointer;
  display: flex;
  align-self: start;
  color: var(--text-color-dark);
}

.kana-menu-list-button {
  font-size: 2vh;
  cursor: pointer;
  display: flex;
  align-self: start;
  color: var(--text-color-dark);
}

.kana-menu-alphabet-button {
  font-size: 1.4vh;
  width: fit-content;
  margin: auto;
  cursor: pointer;
  border-radius: 0.6em;
  padding: 0.4em;
  padding-left: 1.4em;
  padding-right: 1.4em;
  border: 1px solid var(--text-color-dark);
  color: var(--text-color-dark);
}

.kana-character-menu-container {
  padding-top: 4vh;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(17, 1fr);
  margin: auto;
  gap: 0.75vh;
  width: fit-content;
}

.kana-menu-romaji-label {
  font-size: 1.4vh;
  align-content: center;
  justify-content: center;
  padding-left: 1vh;
  cursor: pointer;
  color: var(--text-color-dark);
  align-content: center;
  justify-content: center;
}

.kana-select-all-container {
  display: flex;
    flex-direction: row;
    padding-top: 1em;
    width: 22.727272vh;
    margin: auto;
    padding-left: 2vh;

}

.kana-select-all {
  font-size: 1.4vh;
  align-content: center;
  justify-content: center;
  padding-left: 1vh;
  cursor: pointer;
  color: var(--text-color-dark);
  align-content: center;
  justify-content: center;
  margin: auto;
}

.selected-romaji-label {
  color: var(--text-color-darker);
  font-weight: bold;

}

.kana-menu-romaji-label:hover {
  color: var(--text-color-darker);
  font-weight: bold;
}

.kana-menu-character {
  width: 4vh;
  height: 4vh;
  font-size: 2.2vh;
  align-content: center;
  justify-content: center;
  padding-left: 1vh;
  cursor: pointer;
  color: var(--text-color-dark);
}

.kana-menu-character:hover {
  color: var(--text-color-darker);
}

.selected-kana {
  background-color: var(--selected-kana-bg);
  color: var(--text-color-dark) !important;
  border-radius: 50px;
}
